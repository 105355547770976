/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 14.07.22
 */

export default {
    apiBaseUrl: 'https://intranet.jusdaeurope.com/wp-json/eboard/api',
    endpoint: {
        login: '/authorization/login',
        getLanguages: '/languages',
        getPosts: '/{lang}/posts'
    }
}
