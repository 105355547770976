/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 03.08.22
 */

import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);

        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <p>Nastal nějaký problém. Zkuste to později.</p>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
