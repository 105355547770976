/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 28.07.22
 */
import React from 'react';
import moment from 'moment';
import parse from 'html-react-parser';

class NewsPost extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let post = this.props.data;

        return (
            <div className={'wp-post'}>
                <header className={'wp-post__header'}>
                    <h1>{post.title}</h1>
                </header>
                <div className={'wp-post__main'}>
                    <div className={'wp-post__main--left'}>
                        <div className={'wp-post__date'}>
                            {moment(post.date).format('D.M.')}
                        </div>
                        <div className={'wp-post__thumbnail'}>
                            {post.thumbnail &&
                                <img src={post.thumbnail} alt={''} />
                            }
                        </div>
                    </div>
                    <div className={'wp-post__main--right'}>
                        <div className={'wp-post__excerpt'}>
                            {post.excerpt &&
                                <p>{post.excerpt}</p>
                            }
                        </div>
                        <div className={'wp-post__content'}>
                            {parse(post.content)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsPost;
