/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 15.07.22
 */

import LocalStorageService from '../localStorageService';
import {encode} from 'base-64';
const axios = require('axios');

const localStorageService = LocalStorageService.getService();

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        const basicToken = encode('devel:VrEQQ4Br');
        const token = localStorageService.getAccessToken();

        config.headers['Authorization'] = 'Basic '+basicToken;
        if(token) {
            // config.headers['access-token'] = token;
            config.headers['access-token'] = 'Bearer ' + token;
        }
        return config;
    }
)

axiosInstance.interceptors.response.use((response) => {
    return response;
}, function(error) {
    // const originalRequest = error.config;
    // if(error.response.status === 401) {
    //access_token
    //     originalRequest._retry = true;
    //
    //     return axios.post('')
    //         .then(res => {
    //             if(res.status === 201) {
    //                 localStorageService.setToken(res);
    //
    //                 axios.defaults.headers.common['access-token'] = localStorageService.getAccessToken();
    //
    //                 return axios(originalRequest);
    //             }
    //         })
    // }
    console.log(error);
})

export {axiosInstance};
