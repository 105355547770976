/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 27.07.22
 */
import React from 'react';
import moment from 'moment';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faThumbsUp } from '@fortawesome/free-regular-svg-icons';

class FacebookFeed extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let fb_posts = this.props.data;

        return (
            <Masonry
                breakpointCols={4}
                className="my-masonry-grid fb-feed"
                columnClassName="my-masonry-grid_column">
                {fb_posts.map((post,index) => {
                    return (
                        <div className={'fb-post'} style={{display:'flex'}} key={'fb-post-'+index}>
                            <header className="fb-post__header">
                                <div className="fb-post__user">
                                    {post.from.name}
                                </div>
                                <div className={'fb-post__date'}>
                                    <FontAwesomeIcon icon={faClock} /> {moment(post.created_time).format('D.M.Y')}
                                </div>
                            </header>
                            {post.message &&
                                <div className={'fb-post__content'}>
                                    <p className="fb-post__message">{post.message}</p>
                                </div>
                            }
                            {post.full_picture &&
                                <div className={'fb-post__image'}>
                                    <img src={post.full_picture} />
                                </div>
                            }
                            <footer className={'fb-post__footer'}>
                                <FontAwesomeIcon icon={faThumbsUp} /> {post.reactions.summary.total_count}
                            </footer>
                        </div>
                    )
                })}
            </Masonry>
        )
    }
}

export default FacebookFeed;
